<template>
  <v-main>
    <Header
      :title="'Incidência para o CPRP'"
      :subtitle="'Gerenciamento das atualizações dos leiautes do e-Social'"
      :itemsBreadCrumb="itemsBreadCrumb"
      :hasUpdateInfo="false"
      :hasAddButton="true"
      :addButtonText="'Adicionar incidência'"
      @clickAdd="openModal"
    />
    <div id="tables-container">
      <v-card class="pl-0 pr-0 pt-0 pb-0">
        <v-data-table
          :headers="headers"
          :items="incidences"
          :no-data-text="noDataText"
          class="elevation-1"
          :loading="loading"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [50, 100, 250],
            itemsPerPageText: `Incidência por página: `,
            disableItemsPerPage: incidences.length < 50,
          }"
        >
          <template v-slot:footer.page-text="items">
            {{ items.pageStart }} - {{ items.pageStop }} de {{ totalIncidences }} incidências
          </template>

          <template v-slot:item.name="{ item }">
            <AGTooltip v-if="checkLength(item.name, 75)" :tooltipText="item.name">
              <template slot:tooltipArea>
                <span class="text-no-wrap">
                  {{ truncateText(item.name, 75) }}
                </span>
              </template>
            </AGTooltip>
            <div v-else>
              <span class="text-no-wrap">
                {{ item.name }}
              </span>
            </div>
          </template>

          <template v-slot:item.user="{ item }">
            <AGTooltip v-if="checkLength(item.user)" :tooltipText="item.user">
              <template slot:tooltipArea>
                <span class="text-no-wrap">
                  {{ truncateText(item.user) }}
                </span>
              </template>
            </AGTooltip>
            <div v-else>
              <span class="text-no-wrap">
                {{ item.user }}
              </span>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" id="idEdit" @click="openModalEdit(item)">
                  <v-icon small color="success"> mdi-pencil-outline </v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" id="idHistoric" @click="openModalHistoric(item)">
                  <v-icon small class="mr-2"> mdi-history</v-icon>
                </v-btn>
              </template>
              <span>Histórico</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <ModalIncidenceCPRPNew ref="modalIncidenceCPRPNew" @refresh="refreshFetch" :incidences="incidences" />
    <ModalIncidenceCPRPEdit ref="modalIncidenceCPRPEdit" @refresh="refreshFetch" :incidence="incidence" />
    <ModalIncidenceCPRPHistoric ref="modalIncidenceCPRPHistoric" :history="arrHistoric" />
    <ModalIncidenceCPRPHistoricUpdate ref="modalIncidenceCPRPHistoricUpdate" :history="arrHistoric" />
  </v-main>
</template>
<script>
import eSocialService from '@/services/eSocialService.js';
import { mapActions, mapState } from 'vuex';
import { truncateText, checkLength } from '@/utils/text.js';

export default {
  name: 'IncidenceCPRP',
  components: {
    ModalIncidenceCPRPEdit: () => import('./components/ModalIncidenceCPRPEdit.vue'),
    ModalIncidenceCPRPNew: () => import('./components/ModalIncidenceCPRPNew.vue'),
    ModalIncidenceCPRPHistoric: () => import('./components/ModalIncidenceCPRPHistoric.vue'),
    ModalIncidenceCPRPHistoricUpdate: () => import('./components/ModalIncidenceCPRPHistoricUpdate.vue'),
    Header: () => import('../../commons/Header.vue'),
    AGTooltip: () => import('../../commons/AGTooltip.vue'),
  },
  created() {
    this.getUsers();
    this.fetch();
  },
  updated() {
    this.customize();
  },
  data() {
    return {
      noDataText: 'Nenhuma incidência encontrada',
      incidences: [],
      incidence: {},
      totalIncidences: 0,
      loading: false,
      headers: [
        { text: 'Código', align: 'start', value: 'code', width: '78px', sortable: false },
        { text: 'Nome', align: 'start', value: 'name', width: 'auto', sortable: false },
        { text: 'Atualização', value: 'updatedAt', width: '188px', sortable: false },
        { text: 'Atualizado por', value: 'user', width: '188px', sortable: false },
        { text: 'Ações', align: 'center', value: 'actions', width: '88px', sortable: false },
      ],
      options: {},
      arrHistoric: [],
      usuarios: [],
    };
  },
  computed: {
    ...mapState('usuarios', {
      users: (state) => state.users,
    }),
    itemsBreadCrumb() {
      let itemsBreadCrumb = [
        {
          title: 'Home',
          disabled: false,
          href: 'home',
        },
        {
          title: 'Configurações',
          disabled: true,
        },
        {
          title: 'e-Social',
          disabled: true,
        },
        {
          title: 'Tabelas',
          disabled: false,
          href: '/cadastros-gerenciais/e-social/tables',
        },
        {
          title: 'Incidência para o CPRP',
          disabled: true,
          active: true,
        },
      ];
      return itemsBreadCrumb;
    },
  },
  watch: {
    options: {
      handler() {
        this.fetch();
      },
    },
  },
  methods: {
    truncateText,
    checkLength,
    ...mapActions('usuarios', ['getUsers', 'saveUser']),
    openModal() {
      this.$refs.modalIncidenceCPRPNew.$emit('open');
    },
    openModalEdit(item) {
      this.incidence = {};
      this.incidence = item;
      this.incidence.notes = '';
      setTimeout(() => {
        this.$refs.modalIncidenceCPRPEdit.$emit('open');
      }, 500);
    },
    openModalHistoric(item) {
      this.getHistory(item.code);
    },
    openModalHistoricUpdate() {
      this.$refs.modalIncidenceCPRPHistoricUpdate.$emit('open');
    },
    refreshFetch() {
      this.incidence = {};
      this.incidences = [];
      this.fetch();
    },
    async fetch() {
      this.loading = true;
      // this.params.situation = this.filter.situation;
      // this.params.start = this.formatDateMMYYYY(this.filter.start[0]);
      // this.params.end = this.formatDateMMYYYY(this.filter.start[1]);
      // this.incidences = [];
      const response = await eSocialService.rubricAutomationConfig.incidenceCPRPList(this.params);
      this.incidences = response.data.content;
      this.totalIncidences = response.data.totalElements;
      this.loading = false;
    },
    async getHistory(code) {
      this.arrHistoric = [];
      const response = await eSocialService.rubricAutomationConfig.history('cprp_incidence', { code: code });
      if (response) {
        response.data.forEach((element) => {
          let historic = {
            date: element.date,
            user: element.user,
            differences: {
              after: {
                code: '',
                desc: element.differences ? element.differences.after.desc : '',
                name: element.differences ? element.differences.after.name : '',
                situation: element.differences ? element.differences.after.situation : '',
                start: element.differences ? element.differences.after.start : '',
                user: element.differences ? element.differences.after.user : '',
              },
              before: {
                code: '',
                desc: element.differences ? element.differences.before.desc : '',
                name: element.differences ? element.differences.before.name : '',
                situation: element.differences ? element.differences.before.situation : '',
                start: element.differences ? element.differences.before.start : '',
                user: element.differences ? element.differences.before.user : '',
              },
            },
            notes: element.notes ? element.notes : '',
          };
          this.arrHistoric.push(historic);
          this.$refs.modalIncidenceCPRPHistoric.$emit('open');
        });
      }
    },
    start_and_end(str, lengthStr) {
      if (str != undefined) {
        if (str.length > lengthStr) {
          return str.substr(0, lengthStr) + '...';
        }
        return str;
      }
    },
    customize() {
      let tableLines = document.querySelectorAll('#tables-container tbody tr');
      if (tableLines && tableLines.length > 1) {
        for (let i = 0; i < tableLines.length; i++) {
          tableLines[i].style.backgroundColor = '#FFFFFF';
          if (i % 2) {
            tableLines[i].style.backgroundColor = '#F7F7FD';
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.title {
  margin-top: 32px;

  height: 43px;
  width: 791px;
  left: 0px;
  top: 0px;
  font-family: 'Roboto', sans-serif;
  font-size: 32px !important;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--v-primary-base);
}
.subtitle {
  height: 24px;
  width: 750px;
  left: 0px;
  top: 51px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #575767;
  float: left;
  margin-bottom: 32px !important;
}
.subtitle-link {
  float: right;
  color: #575767;
  margin-bottom: 32px !important;
}
.subtitle-link a {
  text-decoration: none;
}
.icon-home {
  color: #2d5796 !important;
  font-size: 21px;
}
.text-link {
  text-decoration: none;
  color: #8d8d99 !important;
  font-weight: 400 !important;
  letter-spacing: 0.01em !important;
  font-size: 14px !important;
}
.secundary-default {
  height: 48px !important;
  width: 130px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid var(--v-primary-base);
  background-color: #fff !important;
  color: var(--v-primary-base) !important;
}
.secundary-default-active {
  height: 48px !important;
  width: 130px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  border: 1px solid var(--v-primary-base);
  color: #fff !important;
  background-color: var(--v-primary-base) !important;
}

::v-deep #scrollDuplicate,
::v-deep .v-data-table__wrapper {
  overflow-x: auto !important;
}
::v-deep .v-data-table__wrapper table {
  max-height: 330px !important;
}

::v-deep #scrollDuplicate > div {
  height: 1px !important;
}
::v-deep .v-data-table-header {
  text-align: start;
  background-color: #e7e7fa;
  cursor: default;
}

::v-deep .v-data-table-header tr th {
  line-height: 18.62px;
  letter-spacing: 1%;
  height: 51px;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1px;
  cursor: default;
}

::v-deep .v-data-table-header tr th.text-start span {
  color: var(--v-primary-base);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  cursor: default;
}
::v-deep .v-data-table__wrapper table thead.v-data-table-header tr th.text-start {
  border-bottom: none;
}
::v-deep .v-data-table tr td {
  border-bottom: 0px !important;
}
::v-deep .v-data-footer__select {
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: normal !important;
}

::v-deep .v-data-footer__select .v-select {
  margin: 16px 64px 16px 8px !important;
}
::v-deep .v-data-footer__pagination {
  margin: 0px 64px 0px 0px;
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: normal !important;
}
::v-deep .v-data-footer {
  padding-left: 16px;
  padding-right: 16px;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: #e7e7fa;
  color: #8686a2 !important;
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-right: 0;
  padding: 0;
}
::v-deep .v-data-footer__icons-after .v-btn {
  background-color: #e7e7fa;
  color: var(--v-primary-base);
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-left: 0px !important;
  margin-right: 0;
  padding: 0;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: #e7e7fa;
  color: var(--v-primary-base);
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-right: 8px !important;
  padding: 0;
}
::v-deep .v-data-footer .v-input__slot {
  background: #e3ffee !important;
  color: var(--v-primary-base);
}
::v-deep .v-data-footer .v-data-footer__select .v-select {
  background: #ffffff !important;
  color: var(--v-primary-base);
  margin-top: 16px;
  margin-bottom: 0px;
}

::v-deep .v-data-footer__icons-after .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
  padding: 0 !important;
}

::v-deep .v-data-footer__icons-after .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
  padding: 0 !important;
}

::v-deep .v-data-footer__icons-before:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  color: var(--v-primary-base);
  border-radius: 100% !important;
  box-shadow: none !important;
  transition: none !important;
  opacity: 1 !important;
  padding: 0 !important;
}

::v-deep .v-data-footer .v-select__slot .v-input__append-inner {
  padding-top: 7px;
}
::v-deep .v-select__slot .v-select__selections .v-select__selection {
  font-family: Roboto !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #8686a2 !important;
}

::v-deep .v-data-footer .v-select__slot {
  background-color: #e7e7fa;
  height: 48px;
  width: 83px;
  border-radius: 4px;
  border: 1px solid #e7e7fa;
  padding-left: 15px;
  padding-right: 10px;
}

::v-deep .v-data-footer__icons-after:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
  box-shadow: none !important;
  opacity: 1 !important;
  transition: none !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn--disabled {
  padding: 0 !important;
  background-color: #e7e7fa !important;
  border-radius: 100% !important;
}
::v-deep .v-data-footer__icons-after .v-btn--disabled {
  background-color: #e7e7fa !important;
  padding: 0 !important;
  border-radius: 100% !important;
}
::v-deep .v-data-footer__icons-after .v-btn--disabled .v-btn__content .v-icon {
  margin-right: 0px !important;
}
::v-deep .v-data-footer__icons-before .v-btn--disabled .v-btn__content .v-icon {
  margin-right: 4px !important;
}
::v-deep .v-data-footer__select .v-input__control .v-input__slot::before {
  border: none !important;
}
::v-deep .v-data-footer__select .v-input__control .v-input__slot::after {
  border: none !important;
}

.clear-filter-disabled {
  width: 352px;
  max-height: 48px !important;
  min-height: 48px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  background-color: #e7e7fa !important;
  color: #8686a2;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #e7e7fa !important;
  color: #8686a2 !important;
}
.clear-filter-enabled {
  width: 352px;
  max-height: 48px !important;
  min-height: 48px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  border: 1px solid var(--v-primary-base);
  background-color: #fff !important;
  color: var(--v-primary-base) !important;
}
#tables-container .v-card {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
::v-deep .v-data-table tr th.text-center {
  border-bottom: 0px !important;
}
::v-deep .v-data-table tr td.text-center {
  padding-right: 0px !important;
}
::v-deep .v-data-table-header tr th.text-center span {
  color: var(--v-primary-base);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  cursor: default;
}
::v-deep #tables-container .v-card {
  box-shadow: 0px 0px !important;
}
::v-deep #tables-container {
  border-radius: 4px !important;
}
.v-application .elevation-1 {
  box-shadow: 0px 0px !important;
}
</style>
